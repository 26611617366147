@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --offwhite: darken(white, 10%);
  --darkblue: rgb(73, 72, 83); /* 50, 50, 60 */
  --darkgray: #534f4b;
  --maingray: #c1c1c4;
  --red: #e34c26;
  --softRed: #FFB2B2;
  --hoverRed: #FF9797;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  /* font-weight: 900;
  font-style: italic; */
  background-color: rgb(73, 72, 83);
  background-color: var(--darkblue);
  color: white;
  height: 100vh;
  min-width: 400px;
}

.container {
  display: flex;
}

@media only screen and (min-width: 950px) and (max-width: 1150px) {
  .btn-text {
    font-size: .85rem;
  }
}

.btn {
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 240px;
  cursor: pointer;
}

.btn-one {
  color: #fff;
  transition: all 0.3s;
  position: relative;
}
.btn-one span {
  transition: all 0.3s;
}
.btn-one::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: rgba(255, 255, 255, 0.5);
  border-bottom-color: rgba(255, 255, 255, 0.5);
  transform: scale(0.1, 1);
}
.btn-one:hover span {
  letter-spacing: 2px;
}
.btn-one:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}
.btn-one::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
}
.btn-one:hover::after {
  opacity: 0;
  transform: scale(0.1, 1);
}

