@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

:root {
  --offwhite: darken(white, 10%);
  --darkblue: rgb(73, 72, 83); /* 50, 50, 60 */
  --darkgray: #534f4b;
  --maingray: #c1c1c4;
  --red: #e34c26;
  --softRed: #FFB2B2;
  --hoverRed: #FF9797;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  /* font-weight: 900;
  font-style: italic; */
  background-color: var(--darkblue);
  color: white;
  height: 100vh;
  min-width: 400px;
}
